body {
  background: #f1f9ff;
  margin: 0;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root {
  display: flex;
  min-height: 100%;
  flex-direction: column;
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #afafafbd;
  border-radius: 2px;
}

::-webkit-scrollbar-corner {
  overflow: overlay;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #afafaf;
}


html, body {
  scrollbar-color: #afafaf transparent;
  scrollbar-width: thin;
  overflow: overlay;
}